import classNames from 'classnames';
import { FC } from 'react';
import { getFormattedPrice } from '../../../../utils/getFormattedDiff';
import {
  StockDecreaseIconSvg,
  StockIncreaseIconSvg,
} from '../../../../assets/images';
import { SoundButton } from '../../../../components/SoundButton';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';

interface ScenarioViewerProps {
  index: number;
  answer_reward: number;
  failed_panelty: number;
  content: string;
  answers: {
    first: string;
    second: string;
    third: string;
  };
  select: string;
  selectable?: boolean;
  onSelectAnswer?: (answer: string) => void;
}

const AnswerItem: FC<{
  index: number;
  children: any;
  active: boolean;
  selectable?: boolean;
  onSelect?: () => void;
  selectIsEmpty?: boolean;
}> = ({
  active,
  children,
  index,
  onSelect,
  selectable = false,
  selectIsEmpty = false,
}) => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  // selectIsEmpty === true 번호 배경 #FFF 글씨 #2b2c2d
  // selectIsEmply === false && active === false 는 현행 active === false
  // selectIsEmply === false && active === true 는 현행 active === true
  return (
    <SoundButton
      className={classNames('flex w-full group-item group', {
        'bg-canaryYello': active,
        'hover:bg-canaryYello': selectable,
      })}
      style={{
        minHeight: 55,
        padding: '11px 60px 12px',
        marginLeft: -1,
        marginRight: -1,
      }}
      disabled={selectable === false}
      onClick={onSelect}
      playSound={shouldPlaySound}
    >
      <div
        className={classNames(
          'w-8 h-8 rounded-full mr-4 flex justify-center items-center',
          {
            'bg-dark': active === true && selectIsEmpty === false,
            'bg-lightColor': active === false && selectIsEmpty === false,
            // "bg-brownishGrey": active === false && selectIsEmpty === false,
            'bg-white': selectIsEmpty === true,
            'group-hover:bg-dark': selectable,
          }
        )}
        style={{
          minWidth: 32,
          minHeight: 32,
          backgroundColor:
            active === false && selectIsEmpty === false ? '#f0f0f0' : undefined,
        }}
      >
        <span
          className={classNames('font-bold translate-y-0.5', {
            // "text-dark":
            //   (active === false && selectIsEmpty === false) ||
            //   selectIsEmpty === true,
            // // "text-white": active === true && selectIsEmpty === false,
            // "text-white": !active,
            'group-hover:text-white': selectable,
            'text-white': active, // 선택된 답변은 흰색 글씨
            'text-dark': !active, // 선택되지 않은 답변은 어두운색 글씨
          })}
          style={{
            fontSize: 20,
            // color: !active ? "#2b2c2d" : undefined, // 어두운 색 글씨
          }}
        >
          {index}
        </span>
      </div>
      <p
        // 39자 최대 입력
        className={classNames('text-p5 pt-1.5 text-left', {
          // "text-white": !active,
          // // 'text-white font-medium': selectIsEmpty === true,
          'text-dark font-bold': active === true && selectIsEmpty === false,
          // 'text-brownishGrey font-medium':
          // //   active === false && selectIsEmpty === false,
          'group-hover:text-dark': selectable,
          'group-hover:font-bold': selectable,
          'text-dark': active, // 선택된 답변은 어두운색 글씨
          'text-white': !active, // 선택되지 않은 답변은 흰색 글씨
        })}
        style={{ lineHeight: 1.1 }}
      >
        {/* 일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구십일이삼사오육칠팔구 */}
        {children}
      </p>
    </SoundButton>
  );
};

export const ScenarioViewer: FC<ScenarioViewerProps> = ({
  answer_reward,
  answers,
  content,
  failed_panelty,
  index,
  select,
  selectable = false,
  onSelectAnswer,
}) => {
  const theme = useThemeContext();
  const caseColor = theme.pc_case_index_color;
  return (
    <div className='flex flex-col' style={{ height: 626 }}>
      <div
        className='flex justify-between items-center'
        style={{ padding: '0 14px 0 54px', marginBottom: 35 }}
      >
        <p
          className='text-p4 font-bold '
          style={{
            color: caseColor,
          }}
        >
          #사례 {index.toString().padStart(2, '0')}
        </p>
        <div className='flex items-center'>
          <img
            src={StockIncreaseIconSvg}
            style={{ width: 31.1, height: 31.1 }}
            alt='상승'
          />
          <p className='text-coral text-p7 mr-2.5 pt-1.5'>
            기본 상승금 :{' '}
            {getFormattedPrice({
              price: answer_reward,
              prefixSpace: true,
              addSymbol: false,
            })}
            원
          </p>
          <img
            src={StockDecreaseIconSvg}
            style={{ width: 31.1, height: 31.1 }}
            alt='하락'
          />
          <p className='text-dodgerBlue text-p7 pt-1.5'>
            기본 하락금 :{' '}
            {getFormattedPrice({
              price: failed_panelty,
              prefixSpace: true,
              addSymbol: false,
            })}
            원
          </p>
        </div>
      </div>
      <div
        className='overflow-y-auto flex-1'
        style={{
          padding: '0 54px',
          maxHeight: 308,
          marginBottom: 31,
          minHeight: 80,
        }}
      >
        <div
          className='text-white text-p5 whitespace-pre-line'
          dangerouslySetInnerHTML={{ __html: content }}
          style={{ maxHeight: 295, lineHeight: 1.76 }}
        ></div>
      </div>
      <div
        className='bg-white bg-opacity-[0.17] border border-white space-y-[3px] rounded-2xl overflow-y-auto '
        style={{
          padding: '21px 0',
          minHeight: 228,
          maxHeight: 228,
        }}
      >
        <AnswerItem
          index={1}
          active={select === '1'}
          selectable={selectable === true && select.length === 0}
          onSelect={() => onSelectAnswer?.('1')}
          selectIsEmpty={select === ''}
        >
          {answers.first}
        </AnswerItem>
        <AnswerItem
          index={2}
          active={select === '2'}
          selectable={selectable === true && select.length === 0}
          onSelect={() => onSelectAnswer?.('2')}
          selectIsEmpty={select === ''}
        >
          {answers.second}
        </AnswerItem>
        <AnswerItem
          index={3}
          active={select === '3'}
          selectable={selectable === true && select.length === 0}
          onSelect={() => onSelectAnswer?.('3')}
          selectIsEmpty={select === ''}
        >
          {answers.third}
        </AnswerItem>
      </div>
    </div>
  );
};
