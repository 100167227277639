import { FC, useEffect, useState, useMemo } from 'react';
import { GameStep } from '../../../../../constants/step-enum';
import { StepItem } from '../../../../../components/StepItem';
import { Timer } from '../../../../components/Timer';

interface StepHeaderProps {
  orders?: string[] | null;
  step: GameStep;
  expiryTimestamp?: Date[];
}

function StepToStr(step: GameStep, arg?: string) {
  switch (step) {
    case GameStep.STOCK_DASHBOARD:
      if (arg) {
        return `${arg} 님 발언`;
      }
      return '주가 현황';
    case GameStep.FIRST_DECISION:
      return '1차 결정 단계';
    case GameStep.PERSONAL_STATEMENT:
      if (arg) {
        return `${arg} 님 발언`;
      }
      return '개인 발언';
    case GameStep.FREE_DISCUSSION:
      return '자유 토론';
    case GameStep.FINAL_DECISION:
      return '최종 결정';
    case GameStep.STOCK_DEAL:
      if (arg) {
        return `${arg} 님 발언`;
      }
      return '주식 매매';
    default:
      return '알 수 없는 단계';
  }
}

export const StepHeader: FC<StepHeaderProps> = ({
  orders,
  step,
  expiryTimestamp = null,
}) => {
  const [timeIndex, setTimeIndex] = useState(0);
  const expiry = useMemo(() => {
    if (expiryTimestamp) {
      return expiryTimestamp[timeIndex] ?? null;
    }
    return null;
  }, [expiryTimestamp, timeIndex]);

  useEffect(() => {
    if (expiryTimestamp) {
      if (expiryTimestamp.length === 1) {
        setTimeIndex(0);
      }
      const now = new Date();
      const startIndex = expiryTimestamp.findIndex((v) => v > now);

      if (startIndex > -1) {
        setTimeIndex(startIndex);
      } else {
        setTimeIndex(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTimestamp]);

  return (
    <div className="flex" style={{ padding: '0 14px', marginBottom: 14 }}>
      <div className="flex-1">
        <p
          className="text-white font-medium text-p8"
          style={{ lineHeight: 1, marginBottom: 18 }}
        >
          현재 단계: {StepToStr(step, orders ? orders[timeIndex] : undefined)}{' '}
          진행중
        </p>
        <div className="flex space-x-[21px]">
          <StepItem active={step === GameStep.STOCK_DASHBOARD}>
            주가 현황
          </StepItem>
          <StepItem active={step === GameStep.FIRST_DECISION}>
            1차 결정
          </StepItem>
          <StepItem active={step === GameStep.PERSONAL_STATEMENT}>
            개인 발언
          </StepItem>
          <StepItem active={step === GameStep.FREE_DISCUSSION}>
            자유 토론
          </StepItem>
          <StepItem active={step === GameStep.FINAL_DECISION}>
            최종 결정
          </StepItem>
          <StepItem active={step === GameStep.STOCK_DEAL} hasArrow={false}>
            주식 매매
          </StepItem>
        </div>
      </div>
      <div style={{ width: 158 }}>
        <p className="text-white font-medium text-p8" style={{ lineHeight: 1 }}>
          남은 시간
        </p>
        {expiry ? (
          <Timer
            expiryTimestamp={expiry}
            onExpire={() =>
              setTimeIndex((prev) =>
                Math.max(
                  Math.min(prev + 1, (expiryTimestamp ?? []).length - 1),
                  0
                )
              )
            }
          />
        ) : (
          <p className="font-digit font-bold text-p1 text-white">--:--</p>
        )}
      </div>
    </div>
  );
};
