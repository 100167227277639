import { useContext } from 'react';
import { PlaySessionDispatch } from './context';

export function usePlaySessionDispatch() {
  const context = useContext(PlaySessionDispatch);
  if (!context) {
    throw new Error('해당 함수는 컨텍스트 내부에서 사용해주세요.');
  }
  return context;
}
