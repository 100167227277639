import { FC } from 'react';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { AnswerSelect } from '../components/AnswerSelect';
import { GameApis } from '../../../../apis/game.apis';
import { DeicisionIconPng } from '../../../../assets/images';
import { getMyTeam } from '../../../../utils/getMyTeam';
import { getPresenterName } from '../../../../utils/getPresenterName';
import { getScenarioInfo } from '../../../../utils/getScenarioInfo';
import { GameStep } from '../../../../constants/step-enum';
import { PresenterChoiceMobileModal } from '../components/PresenterChoiceMobileModal';

interface FirstDecisionProps {}

export const FirstDecision: FC<FirstDecisionProps> = () => {
  const { session, teamCode, userId } = usePlaySessionContext();
  const myTeam = getMyTeam(teamCode, session.teams);
  const presenter = getPresenterName(myTeam?._id ?? '', session);
  const scenario = getScenarioInfo({
    myTeam,
    balance: session.balance,
    playtime: session.playtime,
    round: session.current_round,
    scenarioInfo: session.gameScenarios,
    turn_info: session.turn_info,
  });
  const current_turn = session.turn_info[session.turn_info.length - 1];
  const current_select =
    current_turn?.users_choice?.find((v) => v._id === userId)?.choice ?? '';
  if (!current_select) {
    return <AnswerSelect api={GameApis.selectFirstDecision} />;
  }
  return (
    <div
      className="flex-1 overflow-y-auto"
      style={{ padding: '110px 30px 30px' }}
    >
      <h1
        className="text-darkBlueGray text-center text-m5 font-medium"
        style={{ lineHeight: 1.5 }}
      >
        <strong>1차 결정 단계 진행 중</strong>입니다
        <br />
        잠시만 기다려 주세요
      </h1>
      <img
        src={DeicisionIconPng}
        alt="1차 결정 단계"
        className="block mx-auto"
        style={{ width: 121, height: 150, marginTop: 37.5 }}
      />
      {scenario?.select && !!presenter ? (
        <PresenterChoiceMobileModal
          isOpen={session.current_step === GameStep.FIRST_DECISION_DONE}
          name={presenter}
          select={scenario.select}
        />
      ) : null}
    </div>
  );
};
