import { FC, useEffect, useState } from 'react';
import { ThemeType } from '../../types/ThemeType';
import { ThemeContext } from './context';
import { ThemeApis } from '../../apis/theme.apis';

interface ThemeProviderProps {
  children: any;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeType | null>(null);

  useEffect(() => {
    let mounted = true;

    ThemeApis.get()
      .then((res) => {
        if (mounted === true) {
          setTheme(res);
        }
      })
      .catch(() => {
        //NOTHING
      });

    return () => {
      mounted = false;
    };
  }, []);

  if (!theme) {
    return null;
  }

  return (
    <ThemeContext.Provider value={theme}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
* {
  --theme-button-color: ${theme.button_color}; 
  --theme-button-font-color: ${theme.button_font_color};
  --theme-background-color: ${theme.background_color};
}
`,
        }}
      ></style>
      {children}
    </ThemeContext.Provider>
  );
};
