import { FC, useState } from 'react';
import { Menu } from './Menu';
import { BuyStockList } from './BuyStockList';
import { BuyStock } from './BuyStock';
import { SellStockList } from './SellStockList';
import { SellStock } from './SellStock';

interface Round2StockExchangeProps {}

// type StockExchangeMode =
//   | 'menu'
//   | 'buy-stock-list'
//   | 'buy'
//   | 'sell-stock-list'
//   | 'sell';

export const Round2StockExchange: FC<Round2StockExchangeProps> = () => {
  const [mode, setMode] = useState('menu');
  if (mode === 'menu') {
    return (
      <Menu
        onSelectBuy={() => setMode('buy-stock-list')}
        onSelectSell={() => setMode('sell-stock-list')}
      />
    );
  }
  if (mode === 'buy-stock-list') {
    return <BuyStockList onChangeMode={setMode} />;
  }
  if (mode === 'sell-stock-list') {
    return <SellStockList onChangeMode={setMode} />;
  }

  if (mode.startsWith('buy::')) {
    return <BuyStock mode={mode} onBack={() => setMode('buy-stock-list')} />;
  }
  if (mode.startsWith('sell::')) {
    return <SellStock mode={mode} onBack={() => setMode('sell-stock-list')} />;
  }
  return null;
};
