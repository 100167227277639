import { FC, useMemo, useEffect, useState, useRef } from 'react';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import {
  LogoutBtnPng,
  MyCashActivePng,
  MyCashPng,
  TimerSandClockRedSvg,
  TimerSandClockIndigoSvg,
} from '../../../../assets/images';
import { MyCashModal } from './MyCashModal';
import { useNavigate } from 'react-router-dom';
import { GameApis } from '../../../../apis/game.apis';
import { HttpError } from '../../../../apis/http-error';
import classNames from 'classnames';
import { EndAlarm, LeftThirtySecondAlarm } from '../../../../assets/audio';
import useSound from 'use-sound';
import { SoundButton } from '../../../../components/SoundButton';

interface HeaderProps {
  startDates: Date | null;
  endDates: Date | null;
  onExpiry?: () => void;
}

const Progress: FC<{
  start: Date | null;
  end: Date | null;
  onExpiry?: () => void;
}> = ({
  end,
  start,
  onExpiry = () => console.warn('onExpiry를 구현해주세요.'),
}) => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const [isEndAlram, setIsEndAlram] = useState(false);
  const _start = useMemo(() => {
    const res = start?.getTime?.() ?? -1;
    return isNaN(res) ? -1 : res;
  }, [start]);
  const _end = useMemo(() => {
    const res = end?.getTime?.() ?? -1;
    return isNaN(res) ? -1 : res;
  }, [end]);
  const [now, setNow] = useState(Date.now());
  const diff = useMemo(() => {
    if (_start === -1 || _end === -1) {
      return -1;
    }
    return Math.max(_end - now, 0);
  }, [_end, _start, now]);
  const rate = useMemo(() => {
    if (diff === -1) {
      return -1;
    }
    return Math.min(Math.max((now - _start) / (_end - _start), 0), 1);
  }, [_end, _start, diff, now]);

  const [playLeftThirtySecondAlarm] = useSound(LeftThirtySecondAlarm);
  const [playEndAlarm] = useSound(EndAlarm);

  useEffect(() => {
    if (29 * 1000 < diff && diff < 30 * 1000 && shouldPlaySound) {
      playLeftThirtySecondAlarm();
    }
    if (isEndAlram === true && diff > 1 * 2000) {
      setIsEndAlram(false);
    }
    if (diff <= 1 * 2000) {
      if (isEndAlram === false && shouldPlaySound) {
        setIsEndAlram(true);
        playEndAlarm();
      }
    }
  }, [
    diff,
    isEndAlram,
    playEndAlarm,
    playLeftThirtySecondAlarm,
    rate,
    shouldPlaySound,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (rate === 1) {
      onExpiry();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rate]);

  if (rate === -1) {
    return (
      <div className='relative w-full bg-indigo' style={{ height: 5 }}></div>
    );
  }

  return (
    <div
      className={classNames('relative w-full', {
        'bg-indigo': diff > 30 * 1000,
        'bg-redBar': diff <= 30 * 1000,
      })}
      style={{ height: 5 }}
    >
      <span
        className='absolute left-0 inset-y-0'
        style={{
          backgroundColor: '#e5e5e5',
          width: `${rate * 100}%`,
        }}
      ></span>
      <img
        src={diff > 30 * 1000 ? TimerSandClockIndigoSvg : TimerSandClockRedSvg}
        alt='모래시계'
        style={{
          width: 24,
          height: 29.5,
          left: `max(0px, min(calc(${rate * 100}% - 12px), calc(100% - 24px)))`,
        }}
        className='absolute -top-3'
      />
    </div>
  );
};

export const Header: FC<HeaderProps> = ({ endDates, startDates, onExpiry }) => {
  const { session, teamCode, userId = '' } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const team = session.teams.find((v) => v.code === teamCode);
  const teamName = team?.name ?? 'NONE';
  const user = session.users.find((v) => v._id === userId);
  const userName = user?.name ?? '홍길동';
  const [openMyCash, setOpenMyCash] = useState(false);
  const handleOpenMyCash = () => {
    setOpenMyCash(true);
  };
  const handleCloseMyCash = () => {
    setOpenMyCash(false);
  };
  const navigate = useNavigate();
  const processLogout = useRef(false);
  const handleLogout = () => {
    if (processLogout.current === true) {
      return;
    }
    processLogout.current = true;
    GameApis.leaveUser(session._id, userId)
      .then(() => {
        sessionStorage.removeItem('LastLoginClientId');
        sessionStorage.removeItem('LastLoginInfo');
        navigate('/');
      })
      .catch((e) => {
        const err = e as HttpError;
        alert(err.message);
      })
      .finally(() => {
        processLogout.current = true;
      });
  };

  return (
    <div>
      <MyCashModal isOpen={openMyCash} onClose={handleCloseMyCash} />
      <div className='p-5'>
        <div className='flex justify-end'>
          <SoundButton
            type='button'
            onClick={handleLogout}
            playSound={shouldPlaySound}
          >
            <img
              src={LogoutBtnPng}
              style={{ width: 116 / 2, height: 23 / 2 }}
              alt='로그아웃'
              className='object-cover'
            />
          </SoundButton>
        </div>
        <div className='flex justify-between items-end px-2.5'>
          <div style={{ paddingTop: 8 }}>
            <p className='text-m5 text-darkBlueGray font-medium mb-[6.5px]'>
              {teamName}팀
            </p>
            <div className='flex items-end'>
              <strong className='text-m3 text-darkBlueGray'>{userName}</strong>
              <span
                className='text-m5 text-darkBlueGray font-medium'
                style={{ marginLeft: 3 }}
              >
                님
              </span>
            </div>
          </div>
          <SoundButton
            className='group'
            style={{ marginBottom: 5 }}
            type='button'
            onClick={handleOpenMyCash}
            playSound={shouldPlaySound}
          >
            <img
              src={openMyCash === false ? MyCashPng : MyCashActivePng}
              alt='내 자산'
              style={{ width: 96, height: 41 }}
            />
          </SoundButton>
        </div>
      </div>
      <Progress start={startDates} end={endDates} onExpiry={onExpiry} />
    </div>
  );
};
