import { FC, useEffect, useMemo } from 'react';
import { usePlaySessionContext } from '../../../contexts/play-session/usePlaySessionContext';
import { useNavigate } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { useNow } from '../../../hooks/useNow';
import { LogoutButton } from './components/LogoutButton';

interface WaitPageProps {}

export const WaitPage: FC<WaitPageProps> = () => {
  const playSession = usePlaySessionContext();
  const myTeam = playSession.session.teams.find(
    (v) => v.code === playSession.teamCode,
  );
  const myTeamUsers = playSession.session.users.filter(
    (v) => v.team_id === myTeam?._id && v.is_disabled !== true,
  );
  const nowDate = useNow();
  const joinedStudents = useMemo(() => {
    return myTeamUsers.filter((v) => {
      const result = new Date(v.valid_date) >= nowDate;
      return result && v.is_disabled !== true;
    }).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowDate]);
  // const joinedStudents = myTeamUsers.filter((v) => v.is_joined === true).length;
  const totalStudents = myTeamUsers.length;
  const navigate = useNavigate();
  const qrCodeUrl =
    // 'http://121.138.109.111:3000' +
    window.location.origin + window.location.pathname + '?t=' + myTeam?.code ??
    'NONE';

  const handleLogout = async () => {
    navigate('/', {
      state: { session: null, teamCode: null },
    });
  };

  useEffect(() => {
    if (!myTeam) {
      alert('소속된 팀을 찾을 수 없습니다.');
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playSession]);

  if (totalStudents > 0 && totalStudents === joinedStudents) {
    return (
      <div className="w-full h-full flex items-center justify-center flex-col bg-theme">
        <h1 className="text-p3 text-white" style={{ lineHeight: 2.29 }}>
          곧 게임이 시작됩니다
          <br />
          잠시만 기다려 주세요
        </h1>
      </div>
    );
  }

  return (
    <div className="relative w-full h-full flex items-center justify-center flex-col">
      <div className="absolute top-[50px] right-[50px]">
        <LogoutButton onClick={handleLogout} />
      </div>
      <h1
        className="font-medium text-darkBlueGray text-p3"
        style={{ marginBottom: 44 }}
      >
        카메라를 이용해 <strong>QR 코드를 스캔</strong>해주세요.
      </h1>
      <h2 className="text-darkBlueGray text-p3" style={{ marginBottom: 25 }}>
        ( 접속 완료 : {joinedStudents}/{totalStudents} )
      </h2>
      <QRCodeCanvas value={qrCodeUrl} size={360} style={{ padding: 40 }} />
      <p
        className="font-medium text-darkBlueGray text-p4"
        style={{ marginTop: -12 }}
      >
        {playSession.teamCode}
      </p>
    </div>
  );
};
