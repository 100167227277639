import { PlaySessionType } from '../types/PlaySession.type';
import { HttpClient } from './http-client';
import { ErrorToHttpError } from './http-error';

async function joinTeam(teamCode: string) {
  try {
    const url = '/game/joinTeam';
    const res = await HttpClient.post(url, { teamCode });
    return res.data as PlaySessionType;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
async function joinUser(teamCode: string, course_id: string, userId: string) {
  try {
    const url = '/game/joinUser';
    const res = await HttpClient.post(url, { teamCode, course_id, userId });
    return res.data as { clientId: string; content: string };
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
async function leaveUser(sessionId: string, userId: string) {
  try {
    const url = '/game/leaveUser';
    await HttpClient.post(url, { sessionId, userId });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
async function userAutoLogin(authStr: string) {
  try {
    const url = '/game/autoLogin';
    const res = await HttpClient.get(url, {
      headers: {
        Authorization: `Bearer ${authStr}`,
      },
    });
    return res.data as { userId: string; session: PlaySessionType };
  } catch {
    return null;
  }
}
async function selectFirstDecision(opts: {
  userId: string;
  session_id: string;
  answer: string;
}) {
  try {
    const url = '/game/first-decision';
    await HttpClient.post(url, {
      session_id: opts.session_id,
      userId: opts.userId,
      answer: opts.answer,
    });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function selectFinalDecision(opts: {
  userId: string;
  session_id: string;
  answer: string;
}) {
  try {
    const url = '/game/final-decision';
    await HttpClient.post(url, {
      session_id: opts.session_id,
      userId: opts.userId,
      answer: opts.answer,
    });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

export type StockDealRecepit = {
  stock: {
    name: string;
    amount: number;
    diff: number;
  };
  cash: {
    current: number;
    diff: number;
  };
};

async function buyStock(opts: {
  userId: string;
  session_id: string;
  stockId: string;
  amount: number;
}) {
  try {
    const url = '/game/stock/buy';
    const res = await HttpClient.post(url, {
      session_id: opts.session_id,
      userId: opts.userId,
      stockId: opts.stockId,
      amount: opts.amount,
    });
    return res.data as StockDealRecepit;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
async function sellStock(opts: {
  userId: string;
  session_id: string;
  stockId: string;
  amount: number;
}) {
  try {
    const url = '/game/stock/sell';
    const res = await HttpClient.post(url, {
      session_id: opts.session_id,
      userId: opts.userId,
      stockId: opts.stockId,
      amount: opts.amount,
    });
    return res.data as StockDealRecepit;
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}

async function useFullBuy(opts: { userId: string; session_id: string }) {
  try {
    const url = '/game/item/full-buy';
    await HttpClient.post(url, {
      sessionId: opts.session_id,
      userId: opts.userId,
    });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
async function useBaedangum(opts: { userId: string; session_id: string }) {
  try {
    const url = '/game/item/baedangum';
    await HttpClient.post(url, {
      sessionId: opts.session_id,
      userId: opts.userId,
    });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
async function useLoan(opts: { userId: string; session_id: string }) {
  try {
    const url = '/game/item/loan';
    await HttpClient.post(url, {
      sessionId: opts.session_id,
      userId: opts.userId,
    });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
async function useInfo(opts: { userId: string; session_id: string }) {
  try {
    const url = '/game/item/getInfo';
    await HttpClient.post(url, {
      sessionId: opts.session_id,
      userId: opts.userId,
    });
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
async function selectTeamDecision(body: {
  sessionId: string;
  teamId: string;
  answer: string;
}) {
  try {
    const url = '/game/team-decision';
    await HttpClient.post(url, body);
  } catch (e: any) {
    throw ErrorToHttpError(e);
  }
}
export const GameApis = {
  joinTeam,
  joinUser,
  userAutoLogin,
  selectFirstDecision,
  selectFinalDecision,
  buyStock,
  sellStock,
  useFullBuy,
  useBaedangum,
  useLoan,
  useInfo,
  selectTeamDecision,
  leaveUser,
};
