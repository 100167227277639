import { FC } from 'react';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
type TeamId = string;

interface StockChartProps {
  myTeamId: TeamId;
  data: Record<TeamId, number>[];
}

function getY(val: number, min: number, max: number): number {
  let mo = max - min;
  if (max === min) {
    mo = 1;
  }
  const unitHeight = (443 - 20) / mo;
  return unitHeight * (val - min);
}
const Line: FC<{
  data: number[];
  color: string;
  max: number;
  min: number;
  drawLine?: boolean;
  zIndex?: number;
}> = ({ data, color, max, min, drawLine = false, zIndex = 0 }) => {
  const _val = data[data.length - 1];
  const path =
    `M 0 ${443 - getY(data[0], min, max)} ` +
    data
      .slice(1)
      .map(
        (point, i) =>
          `L ${(1180 / (data.length - 1)) * (i + 1)} ${
            443 - getY(point, min, max)
          }`,
      )
      .join(' ');
  const style = {
    strokeWidth: 3,
    stroke: color,
    fill: 'none',
  };
  return (
    <>
      <path
        d={path}
        style={{
          ...style,
          zIndex,
        }}
      />
      {drawLine ? (
        <path
          d={`M 0 ${443 - getY(_val, min, max)} L1280 ${
            443 - getY(_val, min, max)
          }`}
          style={{
            strokeWidth: 1,
            stroke: '#fff',
            fill: 'none',
            strokeDasharray: '6 6',
          }}
        />
      ) : null}
      <circle cx={1180} cy={443 - getY(_val, min, max)} r={8} fill={color} />
    </>
  );
};

const FirstLine: FC<{
  data: number[];
  color: string;
  max: number;
  min: number;
  drawLine?: boolean;
  zIndex?: number;
}> = ({ data, color, max, min, drawLine = false, zIndex = 0 }) => {
  const _val = data[data.length - 1];
  return (
    <>
      {drawLine ? (
        <>
          <path
            d={`M 0 ${443 - getY(_val, min, max)} L1172 ${
              443 - getY(_val, min, max)
            }`}
            style={{
              strokeWidth: 1,
              stroke: '#fff',
              fill: 'none',
              strokeDasharray: '6 6',
            }}
          />
          <text
            x="1272"
            y={`${443 - getY(_val, min, max)}`}
            fill="#fff"
            dy="5"
            textAnchor="end"
            fontSize="20"
            fontWeight=""
          >
            시작 주가
          </text>
        </>
      ) : null}
    </>
  );
};

export const StockCharts: FC<StockChartProps> = ({ data, myTeamId }) => {
  const { session } = usePlaySessionContext();
  const startStockPrice = session?.balance?.start_stock_price ?? 0;

  const startStockLineData = data.map(() => startStockPrice);
  const lastItem = data[data.length - 1];
  const maxValue = data.reduce((acc, val) => {
    const values = Object.values(val);
    for (const _val of values) {
      if (acc < _val) {
        acc = _val;
      }
    }
    return acc;
  }, 0);
  const minValue = data.reduce((acc, val) => {
    const values = Object.values(val);
    for (const _val of values) {
      if (acc > _val || isNaN(acc)) {
        acc = _val;
      }
    }
    return acc;
  }, NaN);
  const myTeamLast = lastItem[myTeamId];

  if (!lastItem) {
    return null;
  }

  return (
    <div className="relative">
      <svg
        width={1280}
        height={510}
        viewBox="0 0 1280 510"
        className="relative"
      >
        <FirstLine
          data={startStockLineData}
          color="#5a5a5a"
          max={maxValue}
          min={minValue}
          drawLine
        />
        {Object.keys(lastItem)
          .sort((key) => {
            const isMyTeam = key === myTeamId;
            return isMyTeam ? 1 : -1;
          })
          .map((key) => {
            const numsData = data.map((v) => {
              const value = v[key];
              return value ?? 0;
            });
            const color = myTeamId === key ? '#ffed0d' : '#5a5a5a';
            const lastPrice = numsData[numsData.length - 1];
            const isDraw = startStockPrice !== lastPrice && myTeamId === key;
            return (
              <Line
                data={numsData}
                color={color}
                max={maxValue}
                min={minValue}
                drawLine={isDraw}
              />
            );
          })}
      </svg>
      <div
        className="absolute bg-coral"
        style={{
          top: -24,
          left: 0,
          transform: `translate(1180px, ${
            443 - getY(myTeamLast, minValue, maxValue)
          }px)`,
        }}
      >
        <div
          className="absolute -translate-x-1/2 -translate-y-full"
          style={{ zIndex: 1 }}
        >
          <div className="relative bg-skYellow px-3 py-2 rounded-lg">
            <p className="whitespace-nowrap text-p7 font-medium text-black pt-1">
              <strong className="text-p4 ">
                {myTeamLast.toLocaleString('ko-KR')}
              </strong>
              원
            </p>
            <div className="absolute left-1/2 bottom-0 w-0 h-0 border-x-[10px] border-x-transparent border-t-[14px] border-t-skYellow -translate-x-1/2 -mb-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
