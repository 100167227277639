import classNames from 'classnames';
import { FC, useState } from 'react';
import { BottonSheetModal } from './BottonSheetModal';
import { HttpError } from '../../../../apis/http-error';
import { GameApis } from '../../../../apis/game.apis';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';
import { SoundButton } from '../../../../components/SoundButton';

interface AnswerSelectProps {
  api: (opts: {
    userId: string;
    session_id: string;
    answer: string;
  }) => Promise<void>;
}

export const AnswerSelect: FC<AnswerSelectProps> = ({ api }) => {
  const theme = useThemeContext();
  const [currentSelect, setCurrentSelect] = useState('');
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const { session, userId } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const handleSubmit = async () => {
    try {
      if (!userId) {
        throw new Error('사용자 정보가 존재하지 않습니다.');
      }
      await GameApis.selectFirstDecision({
        answer: currentSelect,
        session_id: session._id,
        userId,
      });
    } catch (e) {
      const err = e as any as HttpError;
      alert(err.message);
      throw e;
    }
  };
  //선택된 번호 버튼 보더 색상
  const selectedColor = theme.number_color_selected;
  const submitBackgroundColor = theme.modal_submit_answer_box_color;
  const answerBoxBackground = theme.submit_answer_box_color;
  return (
    <div
      className='flex-1 overflow-y-auto'
      style={{ padding: '26.5px 20px 20px' }}
    >
      <BottonSheetModal
        isOpen={openSelectModal}
        onClose={() => setOpenSelectModal(false)}
        onSubmit={handleSubmit}
        submitBackgroundColor={submitBackgroundColor}
      >
        <div style={{ padding: 40, paddingBottom: 2.5 }}>
          <p className='text-center text-darkBlueGray text-m5'>
            선택하신 보기로
            <br />
            <strong>정답 제출</strong>하시겠습니까?
          </p>
        </div>
      </BottonSheetModal>
      <h1
        className='text-center text-m5 font-medium text-darkBlueGray'
        style={{ marginTop: 85, marginBottom: 31, lineHeight: 1.5 }}
      >
        PC에서 사례 확인 후
        <br />
        <strong>아래의 보기를 선택</strong>해 주세요.
      </h1>
      <div className='space-x-2.5 flex px-2.5'>
        <SoundButton
          type='button'
          className='flex-1 aspect-square rounded-full pt-3'
          style={{
            borderWidth: 2.5,
            borderColor: currentSelect === '1' ? selectedColor : '#172547',
            backgroundColor: currentSelect === '1' ? selectedColor : undefined,
          }}
          onClick={() => setCurrentSelect('1')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '1',
              'text-white': currentSelect === '1',
              'font-medium': currentSelect !== '1',
              'font-bold': currentSelect === '1',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            1
          </span>
        </SoundButton>
        <SoundButton
          type='button'
          className='flex-1 aspect-square rounded-full pt-3'
          style={{
            borderWidth: 2.5,
            borderColor: currentSelect === '2' ? selectedColor : '#172547',
            backgroundColor: currentSelect === '2' ? selectedColor : undefined,
          }}
          onClick={() => setCurrentSelect('2')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '2',
              'text-white': currentSelect === '2',
              'font-medium': currentSelect !== '2',
              'font-bold': currentSelect === '2',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            2
          </span>
        </SoundButton>
        <SoundButton
          type='button'
          className='flex-1 aspect-square rounded-full pt-3'
          style={{
            borderWidth: 2.5,
            borderColor: currentSelect === '3' ? selectedColor : '#172547',
            backgroundColor: currentSelect === '3' ? selectedColor : undefined,
          }}
          onClick={() => setCurrentSelect('3')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '3',
              'text-white': currentSelect === '3',
              'font-medium': currentSelect !== '3',
              'font-bold': currentSelect === '3',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            3
          </span>
        </SoundButton>
      </div>
      <SoundButton
        style={{ marginTop: 150, backgroundColor: answerBoxBackground }}
        className='blue-btn-mo w-full'
        disabled={currentSelect === ''}
        type='button'
        onClick={() => setOpenSelectModal(true)}
        playSound={shouldPlaySound}
      >
        정답 제출
      </SoundButton>
    </div>
  );
};
