import { FC } from 'react';
import { useThemeContext } from '../../../contexts/theme/useThemeContext';

interface WaitPageProps {}

export const WaitPage: FC<WaitPageProps> = () => {
  const theme = useThemeContext();
  const fontColor = theme.font_color;
  return (
    <div className='w-full h-full flex flex-col justify-center items-center bg-theme'>
      <p
        className='text-white text-m5 font-medium'
        style={{ lineHeight: 1.5, color: fontColor }}
      >
        곧 게임이 시작됩니다
        <br />
        잠시만 기다려 주세요
      </p>
    </div>
  );
};
