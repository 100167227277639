import { FC } from 'react';
import { AppComponentProps } from '../types/AppComponent.props';
import { MemoryRouter, Route, Routes } from 'react-router-dom';
import { IndexPage } from './pages';
import { GamePage } from './pages/game';

export const MoApp: FC<AppComponentProps> = () => {
  return (
    <MemoryRouter>
      <Routes>
        <Route path="/" Component={IndexPage}></Route>
        <Route path="/game/*" Component={GamePage}></Route>
      </Routes>
    </MemoryRouter>
  );
};
