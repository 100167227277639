import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";
import { GameStep } from "../../../../constants/step-enum";

export function useAutoStepMo() {
  const playSession = usePlaySessionContext();
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(playSession.session.current_step);
    if (playSession.session.current_step === GameStep.RESULT) {
      return navigate("/game/result");
    }
    if (!playSession.userId) {
      return navigate("/game/user-select");
    }
    if (playSession.session.current_round === 1) {
      switch (playSession.session.current_step) {
        case GameStep.WAIT: {
          navigate("/game/wait");
          break;
        }
        case GameStep.STOCK_DASHBOARD:
          navigate("/game/round1/stock-chart");
          break;
        case GameStep.FIRST_DECISION:
        case GameStep.FIRST_DECISION_DONE:
          navigate("/game/round1/first-decision");
          break;
        case GameStep.PERSONAL_STATEMENT:
          navigate("/game/round1/personal-statement");
          break;
        case GameStep.FREE_DISCUSSION:
          navigate("/game/round1/free-discussion");
          break;
        case GameStep.FINAL_DECISION:
        case GameStep.FINAL_DECISION_DONE:
          navigate("/game/round1/final-decision");
          break;
        case GameStep.STOCK_DEAL:
          navigate("/game/round1/stock-deal");
          break;
        default:
          break;
      }
    } else if (playSession.session.current_round === 2) {
      switch (playSession.session.current_step) {
        case GameStep.WAIT: {
          navigate("/game/round2/wait");
          break;
        }
        case GameStep.STOCK_DASHBOARD: {
          navigate("/game/round2/stock-dashboard");
          break;
        }
        case GameStep.FINAL_DECISION:
        case GameStep.FINAL_DECISION_DONE: {
          navigate("/game/round2/final-decision");
          break;
        }
        case GameStep.TEAM_PRESENTATION: {
          navigate("/game/round2/team-presentation");
          break;
        }
        case GameStep.STOCK_DEAL: {
          navigate("/game/round2/stock-deal");
          break;
        }
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    playSession.session.current_step,
    playSession.session.current_turn,
    playSession.session.current_round,
    playSession.userId,
  ]);
}
