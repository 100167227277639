import { PlaySessionType } from '../types/PlaySession.type';

export function getTeamUsers(
  session: PlaySessionType,
  teamCode: string,
  now = new Date()
): {
  teamName: string;
  name: string;
  info_1: string;
  _id: string;
  isJoined: boolean;
}[] {
  const team = session.teams.find((v) => v.code === teamCode);
  if (!team) {
    return [];
  }
  const users = session.users
    .filter((v) => {
      return v.team_id === team._id && v.is_disabled !== true;
    })
    .map((v) => {
      return {
        teamName: team.name,
        name: v.name,
        info_1: v.info_1,
        _id: v._id,
        isJoined: new Date(v.valid_date) >= now,
      };
    });

  return users;
}
