import { GameStep } from '../constants/step-enum';
import { PlaySessionType } from '../types/PlaySession.type';
import { getMyTeam } from './getMyTeam';

export function getExpiryData(teamCode: string, session: PlaySessionType) {
  const current_step = session.current_step;

  let startDate = new Date(session.currentStepStartDate);
  let endDate = new Date(session.currentStepExpiryDate);
  let startDates = [startDate];
  let endDates = [endDate];
  let orderUsers: { _id: string; name: string }[] | null = null;

  if (
    current_step === GameStep.FINAL_DECISION_DONE ||
    current_step === GameStep.FIRST_DECISION_DONE
  ) {
    return {
      orderUsers: null,
      startDates: [new Date()],
      endDates: [new Date('null')],
    };
  }

  if (current_step === GameStep.PERSONAL_STATEMENT) {
    try {
      const myTeam = getMyTeam(teamCode, session.teams);
      const turn_info = session.turn_info[session.turn_info.length - 1];
      const teamTurnInfo = turn_info.teams.find((v) => v._id === myTeam?._id);
      orderUsers = (teamTurnInfo?.personal_statement_order ?? []).map((v) => {
        const user = session.users.find((_user) => _user._id === v);
        return {
          _id: user?._id ?? 'NONE',
          name: user?.name ?? 'NONE',
        };
      });
      const diff = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
      const slot = diff / orderUsers.length;
      startDates = [];
      endDates = [];
      for (let i = 0; i < orderUsers.length; i++) {
        const _start = new Date(startDate);
        _start.setSeconds(_start.getSeconds() + slot * i);
        const _end = new Date(_start);
        _end.setSeconds(_end.getSeconds() + slot);
        startDates.push(_start);
        endDates.push(_end);
      }
    } catch {
      return {
        orderUsers: null,
        startDates: [startDate],
        endDates: [endDate],
      };
    }
  }

  return {
    orderUsers: orderUsers,
    startDates: startDates,
    endDates: endDates,
  };
}
