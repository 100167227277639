import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

interface InputBoxProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'type'
  > {}

export const InputBox: FC<InputBoxProps> = (props) => {
  return (
    <input {...props} className={classNames('txtInput', props.className)} />
  );
};
