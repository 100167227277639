import { FC, useState } from 'react';
import { PresenterMenu } from './PresenterMenu';
import { NormalMenu } from './NormalMenu';
import { BuyStock } from './BuyStock';
import { usePlaySessionContext } from '../../../../../contexts/play-session/usePlaySessionContext';
import { getMyTeam } from '../../../../../utils/getMyTeam';
import { getPresenter } from '../../../../../utils/getPresenterName';
import { SellStock } from './SellStock';
import { ItemInfo } from './ItemInfo';
import { DeicisionIconPng } from '../../../../../assets/images';
import { StockDealRecepit } from '../../../../../apis/game.apis';
import { StockRecepitModal } from '../StockRecepitModal';

interface StockExchangeProps {}

export const StockExchange: FC<StockExchangeProps> = () => {
  const { session, userId = '', teamCode = '' } = usePlaySessionContext();
  const myTeam = getMyTeam(teamCode, session.teams);
  const presenter = getPresenter(myTeam?._id ?? '', session);
  const [mode, setMode] = useState('menu');
  const isPresenter = userId === presenter?._id;
  const user = session.users.find((v) => v._id === userId);
  const isDeal =
    (user?.stock_info ?? [])[(user?.stock_info ?? []).length - 1]?.is_deal ??
    false;
  const [recepitModal, setRecepitModal] = useState<{
    data: StockDealRecepit;
    isOpen: boolean;
  }>({
    data: {
      cash: {
        current: 0,
        diff: 0,
      },
      stock: {
        name: 'none',
        amount: 0,
        diff: 0,
      },
    },
    isOpen: false,
  });

  const handleOpenReceipt = (data: StockDealRecepit) => {
    setRecepitModal({ data, isOpen: true });
  };
  const handleCloseReceipt = () => {
    setRecepitModal((prev) => ({ data: prev.data, isOpen: false }));
  };

  return (
    <>
      <StockRecepitModal {...recepitModal} onClose={handleCloseReceipt} />
      {isDeal === true ? (
        <div style={{ padding: '110px 20px 20px' }}>
          <h1
            className="text-center font-medium text-m5 text-darkBlueGray"
            style={{ lineHeight: 1.5 }}
          >
            <strong>주식 매매 단계</strong>가 진행중 입니다
            <br />
            잠시만 기다려 주세요
          </h1>
          <img
            src={DeicisionIconPng}
            alt="대기 아이콘"
            style={{ width: 242 / 2, height: 300 / 2, marginTop: 75 / 2 }}
            className="mx-auto"
          />
        </div>
      ) : mode === 'menu' && isPresenter === true ? (
        <PresenterMenu onChangeMode={setMode} />
      ) : mode === 'menu' && isPresenter === false ? (
        <NormalMenu onChangeMode={setMode} />
      ) : mode === 'buy' ? (
        <BuyStock onBack={() => setMode('menu')} onDeal={handleOpenReceipt} />
      ) : mode === 'sell' ? (
        <SellStock onBack={() => setMode('menu')} onDeal={handleOpenReceipt} />
      ) : mode === 'item' ? (
        <ItemInfo
          onBack={() => setMode('menu')}
          onBackPrevItem={() => setMode('menu')}
        />
      ) : null}
    </>
  );
};
