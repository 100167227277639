import { io } from "socket.io-client";

const URL =
  process.env.NODE_ENV === "production"
    ? // ? 'Theshareholderssk-env.eba-ncp8jkyk.ap-northeast-2.elasticbeanstalk.com/gameSession'
      "https://socialvalueinvestor.com/gameSession"
    : "http://localhost:5001/gameSession";
// : 'http://121.138.109.188:5001/gameSession';

export const gameSocket = io(URL, {
  transports: ["websocket"],
  autoConnect: false,
});
