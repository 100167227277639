import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FC } from "react";
import { MyCashBoxPng, MyCashExitPng } from "../../../../assets/images";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";
import classNames from "classnames";
import {
  getFormattedRate,
  getFormattedDiff,
  getFormattedPrice,
} from "../../../../utils/getFormattedDiff";
import { PlaySessionType } from "../../../../types/PlaySession.type";
import { useThemeContext } from "../../../../contexts/theme/useThemeContext";
import { SoundButton } from "../../../../components/SoundButton";

interface MyCashModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}

function getMyValues(userId: string, session: PlaySessionType) {
  const { base_cash, base_stock, start_stock_price } = session.balance;
  const baseStockValues = base_stock * start_stock_price;
  const baseValues = base_cash + baseStockValues;
  const user = session.users.find((v) => v._id === userId);
  let currStockValues = 0;
  let currCash = 0;
  let currValues = 0;
  const currStocks: {
    _id: string;
    name: string;
    code: string;
    price: number;
    amount: number;
    value: number;
    prev_value: number;
  }[] = [];
  const teamInfo = session.teams.reduce((acc, v) => {
    acc[v._id] = {
      code: v.code,
      name: v.name,
    };
    return acc;
  }, {} as Record<string, { name: string; code: string }>);
  if (user) {
    const stockAmountInfo = {
      curr: user.stock_info[user.stock_info.length - 1]?.stocks ?? [],
      prev:
        user.stock_info[user.stock_info.length - 3]?.stocks ??
        [] ??
        user.stock_info[user.stock_info.length - 2]?.stocks ??
        [],
    };
    const stock = (
      user.stock_info[user.stock_info.length - 1]?.stocks ?? []
    ).filter((v) => v.amount > 0);
    currCash = user.turn_cash[user.turn_cash.length - 1]?.cash ?? 0;
    const stockPrice = session.stocks.reduce((acc, v) => {
      const price_info = (v.price_info ?? []).filter((v, _, arr) => {
        const result = !(arr.length >= 3 && v.turn === 0);
        return result;
      });
      const price = price_info[price_info.length - 1]?.price ?? 0;
      const prevPrice = price_info[price_info.length - 2]?.price ?? 0;
      acc[v._id] = {
        curr: price,
        prev: prevPrice,
      };
      return acc;
    }, {} as Record<string, { curr: number; prev: number }>);

    currStockValues = stock.reduce((acc, v) => {
      acc += v.amount * (stockPrice?.[v._id]?.curr ?? 0);
      return acc;
    }, 0);
    currValues = currStockValues + currCash;
    for (const _stock of user.stock_info[user.stock_info.length - 1]?.stocks ??
      []) {
      const price = stockPrice[_stock._id];
      const prev_amount =
        stockAmountInfo.prev.find((v) => v._id === _stock._id)?.amount ?? 0;

      currStocks.push({
        _id: _stock._id,
        amount: _stock.amount,
        price: price?.curr ?? 0,
        name: teamInfo[_stock._id]?.name ?? "UNKNOWN",
        code: teamInfo[_stock._id]?.code ?? "UNKNOWN",
        value: (price?.curr ?? 0) * _stock.amount,
        prev_value: (price?.prev ?? 0) * prev_amount,
      });
    }
  }
  return {
    base_cash: base_cash,
    base_values: baseValues,
    base_stock_values: baseStockValues,
    curr_cash: currCash,
    curr_values: currValues,
    curr_stock_values: currStockValues,
    curr_stocks: currStocks,
  };
}

function formatCurrency(amount: number) {
  if (amount > 0) {
    let parts = {
      uk: 0, // 억
      man: 0, // 만
      won: 0, // 원
    };
    // 억 단위 계산
    if (amount >= 100000000) {
      parts.uk = Math.floor(amount / 100000000);
      amount = amount % 100000000; // 남은 금액
    }
    // 만 단위 계산
    if (amount >= 10000) {
      parts.man = Math.floor(amount / 10000);
      amount = amount % 10000; // 남은 금액
    }
    // 원 단위
    parts.won = amount;

    return (
      <span>
        {parts.uk > 0 && <span>{parts.uk}억</span>}
        {parts.man > 0 && <span>{parts.man}만</span>}
        {parts.won > 0 && <span>{parts.won}</span>}
      </span>
    );
  } else if (amount === 0) {
    return (
      <span>
        <span>0</span>
      </span>
    );
  } else if (amount < 0) {
    amount *= -1;

    let parts = {
      uk: 0, // 억
      man: 0, // 만
      won: 0, // 원
    };
    // 억 단위 계산
    if (amount >= 100000000) {
      parts.uk = Math.floor(amount / 100000000);
      amount = amount % 100000000; // 남은 금액
    }
    // 만 단위 계산
    if (amount >= 10000) {
      parts.man = Math.floor(amount / 10000);
      amount = amount % 10000; // 남은 금액
    }
    // 원 단위
    parts.won = amount;

    return (
      <span>
        -{parts.uk > 0 && <span>{parts.uk}억</span>}
        {parts.man > 0 && <span>{parts.man}만</span>}
        {parts.won > 0 && <span>{parts.won}</span>}
      </span>
    );
  }
}

export const MyCashModal: FC<MyCashModalProps> = ({
  isOpen = false,
  onClose = () => {
    console.warn("please write onClose");
  },
}) => {
  const theme = useThemeContext();
  const { session, teamCode, userId = "" } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const { base_values, curr_values, curr_cash, curr_stocks } = getMyValues(
    userId,
    session
  );
  const total_diff = curr_values - base_values;
  const total_diff_rate = total_diff / base_values;
  const stockBoxColor = theme.my_stock_box_color;
  const stockBoxContentColor = theme.my_stock_box_content_color;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-[0.43]"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="min-h-full" style={{ padding: "140px 12.5px 0" }}>
              <div className="h-full">
                <div
                  className="rounded-t-lg bg-white h-full"
                  style={{ padding: "25px 24px 0" }}
                >
                  <div className="border-b border-browngrey border-dashed pb-5 mb-5">
                    <div className="flex justify-between items-center">
                      <p className="text-m6 text-browngrey font-medium ">
                        총 자산
                      </p>
                      <p className="text-m6 ">
                        <strong className="text-m4 ">
                          {formatCurrency(curr_values)}
                        </strong>{" "}
                        원
                      </p>
                    </div>
                    <div
                      className="flex justify-end"
                      style={{ marginTop: 12.5 }}
                    >
                      <p
                        className={classNames("text-m7", {
                          "text-coral": total_diff >= 0,
                          "text-dodgerBlue": total_diff < 0,
                        })}
                      >
                        {getFormattedDiff({
                          price: total_diff,
                          rate: total_diff_rate * 100,
                          prefixSpace: false,
                        })}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between items-center">
                      <p className="text-m6 text-browngrey font-medium">
                        보유 현금
                      </p>
                      <p className="text-m6">
                        <span className="text-m4">
                          {getFormattedPrice({
                            prefixSpace: false,
                            price: curr_cash,
                            addSymbol: false,
                          })}
                        </span>{" "}
                        원
                      </p>
                    </div>
                    <p className="text-m6 text-browngrey font-medium mt-5">
                      보유주식
                    </p>
                    {curr_stocks.map((v) => {
                      const _diff = v.value - v.prev_value;
                      const _rate = (_diff / v.prev_value) * 100;
                      return (
                        <div
                          key={v._id}
                          className="mt-3 rounded-xl"
                          style={{
                            backgroundColor: stockBoxContentColor,
                          }}
                        >
                          <div
                            className="rounded-t-xl"
                            style={{
                              padding: "11px 14px",
                              backgroundColor: stockBoxColor,
                            }}
                          >
                            <p className="font-medium text-m7 ">
                              {v.name}팀
                              {v.code === teamCode ? " (우리 팀)" : ""}
                            </p>
                          </div>
                          <div style={{ padding: "0 14px" }}>
                            <div
                              className="border-b border-browngrey border-dashed flex justify-between items-start"
                              style={{ padding: "15px 0" }}
                            >
                              <p className="text-m7 font-medium ">현재 주가</p>
                              <p className="text-m6 font-medium ">
                                <span className="text-m4 ">
                                  {getFormattedPrice({
                                    price: v.price,
                                    prefixSpace: false,
                                    addSymbol: false,
                                  })}
                                </span>{" "}
                                원
                              </p>
                            </div>
                            <div
                              className="border-b border-browngrey border-dashed flex justify-between items-start"
                              style={{ padding: "15px 0" }}
                            >
                              <p className="text-m7 font-medium ">보유 수량</p>
                              <p className="text-m6 font-medium ">
                                <span className="text-m4 ">
                                  {getFormattedPrice({
                                    price: v.amount,
                                    prefixSpace: false,
                                    addSymbol: false,
                                  })}
                                </span>{" "}
                                주
                              </p>
                            </div>
                            <div
                              className="flex justify-between items-start"
                              style={{ padding: "15px 0" }}
                            >
                              <div>
                                <p className="text-m7 font-medium ">
                                  평가 금액
                                </p>
                                <p className="text-m82 font-medium text-right">
                                  (수익률%)
                                </p>
                              </div>
                              <div className="text-right">
                                <p className="text-m6 font-medium ">
                                  <span className="text-m4 ">
                                    {getFormattedPrice({
                                      price: v.value,
                                      prefixSpace: false,
                                      addSymbol: false,
                                    })}
                                  </span>{" "}
                                  원
                                </p>
                                <p
                                  className={classNames(
                                    "text-m7 font-medium text-right  mt-3",
                                    {
                                      "text-coral": _rate >= 0,
                                      "text-dodgerBlue": _rate < 0,
                                    }
                                  )}
                                >
                                  (
                                  {getFormattedRate(_rate, {
                                    plusMinus: true,
                                  })}
                                  )
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  style={{
                    height: 25,
                    marginTop: -1,
                    backgroundImage: `url(${MyCashBoxPng})`,
                  }}
                  className="bg-bottom bg-cover"
                ></div>
                <div className="flex justify-center pb-4 pt-3">
                  <SoundButton
                    type="button"
                    onClick={onClose}
                    playSound={shouldPlaySound}
                  >
                    <img
                      src={MyCashExitPng}
                      alt="닫기"
                      style={{ width: 27, height: 31 }}
                    />
                  </SoundButton>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
