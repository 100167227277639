import { FC } from 'react';
import { BackBtnIconPng } from '../../../../assets/images';
import { SoundButton } from '../../../../components/SoundButton';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';

interface BackButtonProps {
  onClick?: () => void;
}

export const BackButton: FC<BackButtonProps> = ({ onClick }) => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  return (
    <SoundButton type='button' onClick={onClick} playSound={shouldPlaySound}>
      <img
        alt='돌아가기'
        src={BackBtnIconPng}
        style={{ width: 187 / 2, height: 58 / 2 }}
      />
    </SoundButton>
  );
};
