import { FC, Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { HttpError } from '../../../../../apis/http-error';
import { GameApis } from '../../../../../apis/game.apis';
import { usePlaySessionContext } from '../../../../../contexts/play-session/usePlaySessionContext';
import { getScenarioInfo } from '../../../../../utils/getScenarioInfo';
import { SoundButton } from '../../../../../components/SoundButton';

interface GetInfoModalProps {
  isOpen?: boolean;
  onClose?: () => void;
}
const descriptionKeyMap: Record<string, string> = {
  '1': 'first',
  '2': 'second',
  '3': 'third',
};
const Core: FC<GetInfoModalProps> = ({
  isOpen = false,
  onClose = () => {
    console.warn('please write onClose');
  },
}) => {
  const { session, userId = '' } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  const scenario = getScenarioInfo({
    round: session.current_round,
    scenarioInfo: session.gameScenarios,
    turn_info: session.turn_info,
    balance: session.balance,
    playtime: session.playtime,
  });
  const [mode, setMode] = useState('select');
  const [currentSelect, setCurrentSelect] = useState('');

  const description =
    (scenario?.descriptions as any)?.[descriptionKeyMap[currentSelect]] ?? '';
  const isProcess = useRef(false);
  const handleClose = () => {
    if (isProcess.current === true) {
      return;
    }
    onClose();
  };
  const handleSubmit = async () => {
    if (isProcess.current === true) {
      return;
    }
    isProcess.current = true;
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      await GameApis.useInfo({
        session_id: session._id,
        userId: userId,
      });
      setMode('view');
    } catch (e: any) {
      const err = e as HttpError;
      alert(err.message);
    } finally {
      isProcess.current = false;
    }
  };
  if (mode === 'view' && currentSelect.length > 0) {
    return (
      <div
        className='bg-white flex-1 rounded-t-2xl'
        style={{ padding: '26px 20px' }}
      >
        <p className='text-darkBlueGray text-m5 text-center'>
          <strong>정보를 확인</strong>해 주세요.
        </p>
        <div className='mt-2 overflow-auto' style={{ maxHeight: '30vh' }}>
          <p className='text-m6 leading-tight'>{description}</p>
        </div>
        <div className='mt-5'>
          <SoundButton
            type='button'
            className='blue-btn-mo w-full'
            onClick={handleClose}
            playSound={shouldPlaySound}
          >
            닫기
          </SoundButton>
        </div>
      </div>
    );
  }
  return (
    <div
      className='bg-white flex-1 rounded-t-2xl'
      style={{ padding: '26px 20px' }}
    >
      <p className='text-darkBlueGray text-m5 text-center'>
        <strong>정보를 확인</strong>할 보기를
        <br />
        선택해 주세요.
      </p>
      <div className='space-x-2.5 flex my-12'>
        <SoundButton
          type='button'
          className={classNames(
            'border-darkBlueGray flex-1 aspect-square rounded-full pt-3',
            {
              'bg-darkBlueGray': currentSelect === '1',
            }
          )}
          style={{ borderWidth: 2.5 }}
          onClick={() => setCurrentSelect('1')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '1',
              'text-white': currentSelect === '1',
              'font-medium': currentSelect !== '1',
              'font-bold': currentSelect === '1',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            1
          </span>
        </SoundButton>
        <SoundButton
          type='button'
          className={classNames(
            'border-darkBlueGray flex-1 aspect-square rounded-full pt-3',
            {
              'bg-darkBlueGray': currentSelect === '2',
            }
          )}
          style={{ borderWidth: 2.5 }}
          onClick={() => setCurrentSelect('2')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '2',
              'text-white': currentSelect === '2',
              'font-medium': currentSelect !== '2',
              'font-bold': currentSelect === '2',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            2
          </span>
        </SoundButton>
        <SoundButton
          type='button'
          className={classNames(
            'border-darkBlueGray flex-1 aspect-square rounded-full pt-3',
            {
              'bg-darkBlueGray': currentSelect === '3',
            }
          )}
          style={{ borderWidth: 2.5 }}
          onClick={() => setCurrentSelect('3')}
          playSound={shouldPlaySound}
        >
          <span
            className={classNames('text-center', {
              'text-darkBlueGray': currentSelect !== '3',
              'text-white': currentSelect === '3',
              'font-medium': currentSelect !== '3',
              'font-bold': currentSelect === '3',
            })}
            style={{ fontSize: 50, lineHeight: 0.6 }}
          >
            3
          </span>
        </SoundButton>
      </div>
      <div className='mt-5 flex space-x-2.5'>
        <SoundButton
          type='button'
          className='pink-btn-mo w-full'
          onClick={handleClose}
          playSound={shouldPlaySound}
        >
          닫기
        </SoundButton>
        <SoundButton
          type='button'
          className='blue-btn-mo w-full'
          onClick={handleSubmit}
          disabled={currentSelect.length === 0}
          playSound={shouldPlaySound}
        >
          선택
        </SoundButton>
      </div>
    </div>
  );
};

export const GetInfoModal: FC<GetInfoModalProps> = ({
  isOpen = false,
  onClose = () => {
    console.warn('please write onClose');
  },
}) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-40'></div>
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-hidden flex items-end'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-8'
            enterTo='opacity-100 translate-y-0'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0'
            leaveTo='opacity-0 translate-y-8'
          >
            <div className='w-full'>
              <Core isOpen={isOpen} onClose={onClose} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
