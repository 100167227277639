import { useContext } from 'react';
import { PlaySessionContext } from './context';

export function usePlaySessionContext() {
  const context = useContext(PlaySessionContext);
  if (!context) {
    throw new Error('해당 함수는 컨텍스트 내부에서 사용해주세요.');
  }
  return context;
}
