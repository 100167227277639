import { FC, useEffect, useState } from 'react';
import { PlaySessionType } from '../../../../types/PlaySession.type';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { getMyTeam } from '../../../../utils/getMyTeam';
import classNames from 'classnames';
import { getFormattedDiff } from '../../../../utils/getFormattedDiff';
import { ProfitLossReportModal } from '../components/ProfitLossReportModal';
import { GameStep } from '../../../../constants/step-enum';
import { getStockInfo } from '../../../../utils/getStockInfo';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';

interface Round2StockDashBoardProps {}

function getStockData(teamId: string, session: PlaySessionType) {
  const stocks = session.stocks
    .map((v, _, arr) => {
      const result = getStockInfo(v._id, arr);
      const price = result.price;
      const prevPrice = result.prev_price;
      const diff = price - prevPrice;
      const diffRate = (diff / prevPrice) * 100;
      return {
        _id: v._id,
        name: v.name,
        price: price,
        diff: diff,
        diffRate,
      };
    })
    .sort((a, b) => (a.price > b.price ? -1 : 1))
    .map((v) => ({
      ...v,
      price: v.price.toLocaleString('ko-KR'),
    }));
  const myStock = stocks.find((v) => v._id === teamId) ?? null;
  const highestStock = stocks.shift() ?? null;
  const lowestStock = stocks.pop() ?? null;
  return {
    highestStock: highestStock,
    lowestStock: lowestStock,
    // otherStocks: stocks,
    otherStocks: stocks.sort((a, b) => (a.name > b.name ? 1 : -1)),
    myStock: myStock,
  };
}

function useModal() {
  const [state, setState] = useState(false);
  return {
    isOpen: state,
    open: () => setState(true),
    close: () => setState(false),
  };
}

export const Round2StockDashBoard: FC<Round2StockDashBoardProps> = () => {
  const theme = useThemeContext();
  const { session, teamCode } = usePlaySessionContext();
  const myTeam = getMyTeam(teamCode, session.teams);
  const data = getStockData(myTeam?._id ?? '', session);
  const { close, isOpen, open } = useModal();
  const ourTeamNameColor = theme.stock_status_our_team_color;
  const ourTeamBorderColor = theme.stock_status_our_team_border;

  useEffect(() => {
    if (session.current_step === GameStep.STOCK_DASHBOARD) {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.current_turn]);

  return (
    <div className="flex-1 overflow-auto" style={{ padding: '21px 20px' }}>
      <ProfitLossReportModal isOpen={isOpen} onClose={close} />
      <div className="space-y-2 5">
        {data.myStock !== null ? (
          <div
            className="py-2 pb-1 px-4 border rounded-xl"
            style={{
              borderColor: ourTeamBorderColor,
            }}
          >
            <p
              className={`text-m7 font-medium leading-relaxed mb-1`}
              style={{
                color: ourTeamNameColor,
              }}
            >
              우리 팀
            </p>
            <div className="flex justify-between">
              <p className="text-m6 text-darkBlueGray font-medium">
                {data.myStock.name}팀
              </p>
              <div className="text-right">
                <p className="text-m6 text-darkBlueGray font-medium">
                  <strong>{data.myStock.price}</strong> 원
                </p>
                <p
                  className={classNames('text-m7 font-medium leading-relaxed', {
                    'text-coral': data.myStock.diff >= 0,
                    'text-dodgerBlue': data.myStock.diff < 0,
                  })}
                >
                  {getFormattedDiff({
                    price: data.myStock.diff,
                    rate: data.myStock.diffRate,
                  })}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {data.myStock !== null ? (
          <div
            style={{
              margin: '16px 0',
              backgroundColor: '#e2e2e2',
              height: 2,
            }}
          />
        ) : null}
        {data.lowestStock !== null ? (
          <div
            className="py-2 pb-1 px-4 border rounded-xl"
            style={{
              backgroundColor: '#edf2ff',
              borderColor: '#dfe7fd',
            }}
          >
            <p className="text-m7 text-dodgerBlue font-medium leading-relaxed mb-1">
              최저 주가
            </p>
            <div className="flex justify-between">
              <p className="text-m6 text-darkBlueGray font-medium">
                {data.lowestStock.name}팀
              </p>
              <div className="text-right">
                <p className="text-m6 text-darkBlueGray font-medium">
                  <strong>{data.lowestStock.price}</strong> 원
                </p>
                <p
                  className={classNames('text-m7 font-medium leading-relaxed', {
                    'text-coral': data.lowestStock.diff >= 0,
                    'text-dodgerBlue': data.lowestStock.diff < 0,
                  })}
                >
                  {getFormattedDiff({
                    price: data.lowestStock.diff,
                    rate: data.lowestStock.diffRate,
                  })}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {data.highestStock !== null ? (
          <div
            className="py-2 pb-1 px-4 border rounded-xl"
            style={{
              backgroundColor: '#ffebeb',
              borderColor: '#ffd3d3',
            }}
          >
            <p className="text-m7 text-coral font-medium leading-relaxed mb-1">
              최고 주가
            </p>
            <div className="flex justify-between">
              <p className="text-m6 text-darkBlueGray font-medium">
                {data.highestStock.name}팀
              </p>
              <div className="text-right">
                <p className="text-m6 text-darkBlueGray font-medium">
                  <strong>{data.highestStock.price}</strong> 원
                </p>
                <p
                  className={classNames('text-m7 font-medium leading-relaxed', {
                    'text-coral': data.highestStock.diff >= 0,
                    'text-dodgerBlue': data.highestStock.diff < 0,
                  })}
                >
                  {getFormattedDiff({
                    price: data.highestStock.diff,
                    rate: data.highestStock.diffRate,
                  })}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {data.otherStocks.length > 0 ? (
          <div className="py-2.5 px-4">
            <p className="text-m7 leading-relaxed font-medium text-browngrey">
              그 외
            </p>
            {data.otherStocks.map((v) => {
              return (
                <div
                  key={v._id}
                  className="pt-2 pb-4 border-b border-veryLightPink mb-2"
                >
                  <div className="flex justify-between">
                    <p className="text-m6 text-darkBlueGray font-medium">
                      {v.name}팀
                    </p>
                    <div className="text-right">
                      <p className="text-m6 font-medium text-darkBlueGray ">
                        <strong>{v.price}</strong> 원
                      </p>
                      <p
                        className={classNames('text-m7 font-medium mt-4 ', {
                          'text-coral': v.diff >= 0,
                          'text-dodgerBlue': v.diff < 0,
                        })}
                      >
                        {getFormattedDiff({
                          price: v.diff,
                          rate: v.diffRate,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
};
