import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import useSound from 'use-sound';
import { Click } from '../assets/audio';

interface SoundButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  playSound?: boolean;
}

export const SoundButton: FC<SoundButtonProps> = ({
  playSound = true,
  ...props
}) => {
  const [play] = useSound(Click);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // play();
    if (playSound) {
      play();
    }
    if (props.onClick) {
      props.onClick(event);
    }
  };
  return (
    <button {...props} style={props.style} onClick={handleClick}>
      {props.children}
    </button>
  );
};
