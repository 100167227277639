import { FC, Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import classNames from "classnames";
import { getFormattedDiff } from "../../../../utils/getFormattedDiff";
import {
  StockDecreaseIconSvg,
  StockIncreaseIconSvg,
} from "../../../../assets/images";
import { SoundButton } from "../../../../components/SoundButton";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";

interface StockChangeModalProps {
  isOpen: boolean;
  diff: number;
  content: string;
  onClose?: () => void;
}

export const StockChangeModal: FC<StockChangeModalProps> = ({
  isOpen,
  onClose = () => {
    console.warn("please write onClose");
  },
  content,
  diff,
}) => {
  const isPositive = diff > 0;

  const session = usePlaySessionContext();

  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정

  let buyersCount = 0;
  let sellersCount = 0;

  session.session.users.forEach(
    (user: { stock_info: any; turn_cash: any; team_id: string }) => {
      const stockInfo = user.stock_info;
      const myTeamId = session.session.teams.find(
        (v) => v.code === session.teamCode
      )?._id;
      if (user.team_id === myTeamId) {
        if (
          stockInfo.length >= 3 &&
          stockInfo[stockInfo.length - 2].is_deal === true
        ) {
          const order = stockInfo[stockInfo.length - 2].deal_type;

          if (order === "매수") {
            buyersCount++;
          } else if (order === "매도") {
            sellersCount++;
          }
        }
      }
    }
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-40"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{
                  maxWidth: 1280,
                  width: 1280,
                  maxHeight: 800,
                  //height: 800,
                  padding: "117px 50px 51px",
                }}
              >
                <Dialog.Panel
                  className={classNames(
                    "w-full h-full bg-white border flex flex-col justify-center items-center rounded-2xl",
                    {
                      "border-coral": isPositive === true,
                      "border-dodgerBlue": isPositive === false,
                    }
                  )}
                  style={{ borderWidth: 5, padding: "53px 49px 44px" }}
                >
                  <div className="flex justify-center items-end mb-10">
                    <img
                      src={
                        isPositive ? StockIncreaseIconSvg : StockDecreaseIconSvg
                      }
                      alt={isPositive ? "주가상승" : "주가하락"}
                      className="block"
                      style={{ width: 78.6, height: 78.6 }}
                    />
                    <h2
                      className={classNames(
                        "text-p2 text-center font-medium pb-2",
                        {
                          "text-coral": isPositive === true,
                          "text-dodgerBlue": isPositive === false,
                        }
                      )}
                    >
                      주가 {isPositive ? "상승" : "하락"}{" "}
                      <strong>
                        {getFormattedDiff({
                          price: diff,
                          prefixSpace: false,
                        })}
                      </strong>
                      <span style={{ fontSize: 34 }}>원</span>
                    </h2>
                  </div>
                  {!session.round_2_start && (
                    <div
                      style={{
                        position: "absolute",
                        right: "120px",
                        top: "190px",
                      }}
                      className="text-p3 font-medium"
                    >
                      <div>매수 인원 : {buyersCount}명</div>
                      <div>매도 인원 : {sellersCount}명</div>
                    </div>
                  )}
                  <div className="w-full flex-1">
                    <p
                      className="whitespace-pre-line text-p5 text-darkBlueGray font-medium"
                      style={{
                        lineHeight: 1.76,
                        maxHeight: 330,
                        overflow: "auto",
                      }}
                    >
                      {content}
                    </p>
                  </div>
                  <SoundButton
                    type="button"
                    className="bg-darkBlueGray text-white mt-3 text-p4 font-medium rounded-2xl"
                    style={{
                      padding: "20px 68px 16px",
                      height: 60,
                    }}
                    onClick={onClose}
                    playSound={shouldPlaySound}
                  >
                    확인
                  </SoundButton>
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
