import { FC, useState, useEffect } from 'react';
import { MyTeamStock } from '../components/MyTeamStock';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { ProfitLossReportModal } from '../components/ProfitLossReportModal';

interface StockDashboardProps {}

function useModal() {
  const [state, setState] = useState(false);
  return {
    isOpen: state,
    open: () => setState(true),
    close: () => setState(false),
  };
}

export const StockDashboard: FC<StockDashboardProps> = () => {
  const { session } = usePlaySessionContext();
  const { close, isOpen, open } = useModal();

  useEffect(() => {
    if (session.current_turn !== 1) {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.current_turn]);

  return (
    <>
      <MyTeamStock />
      <ProfitLossReportModal isOpen={isOpen} onClose={close} />
    </>
  );
};
