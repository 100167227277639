import { FC } from 'react';
import { PersonalStatementIconPng } from '../../../../assets/images';
import { PlaySessionType } from '../../../../types/PlaySession.type';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';

interface PersonalStatementProps {
  currentUser: string;
  nextUser?: string;
}

function getMySelectData(
  userId: string,
  turnInfos: PlaySessionType['turn_info']
) {
  const turnInfo = turnInfos[turnInfos.length - 1];
  const userChoice = turnInfo.users_choice.find((v) => v._id === userId);
  return userChoice?.choice ?? '';
}

const Icon: FC<{ select: string }> = ({ select }) => {
  const theme = useThemeContext();
  const iconColor = theme.my_choice_color;
  return (
    <span
      className='block bg-darkBlueGray rounded-full'
      style={{
        width: 49 / 2,
        height: 49 / 2,
        paddingTop: 1,
        backgroundColor: iconColor,
      }}
    >
      <span className='text-white inline-block font-bold text-m8 text-center w-full'>
        {select}
      </span>
    </span>
  );
};

export const PersonalStatement: FC<PersonalStatementProps> = ({
  currentUser,
  nextUser,
}) => {
  const { session, userId = '' } = usePlaySessionContext();
  const selectData = getMySelectData(userId, session.turn_info);

  return (
    <div className='flex-1 overflow-y-auto' style={{ padding: '60px 20px 0' }}>
      {selectData !== '' ? (
        <div
          className='flex justify-center items-center'
          style={{ marginBottom: 66 / 2 }}
        >
          <p className='pt-1 mr-1.5 font-medium text-darkBlueGray text-m6 leading-none'>
            나의 선택 :
          </p>
          <Icon select={selectData} />
        </div>
      ) : null}
      <p
        className='text-m5 font-medium text-darkBlueGray text-center'
        style={{ lineHeight: 1.5 }}
      >
        <strong>{currentUser}</strong> 님의
        <br />
        개인 발언 시간입니다
      </p>
      <img
        src={PersonalStatementIconPng}
        alt='개인발언아이콘'
        style={{ width: 198, height: 133, marginTop: 37.5, marginBottom: 40 }}
        className='mx-auto'
      />
      {typeof nextUser === 'string' ? (
        <p className='text-m6 font-medium text-darkBlueGray text-center'>
          다음 차례 : <strong>{nextUser}</strong> 님
        </p>
      ) : null}
    </div>
  );
};
