import { FC } from 'react';
import { LogoutBtnIconPng } from '../../../../assets/images';
import { SoundButton } from '../../../../components/SoundButton';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';

interface LogoutButtonProps {
  onClick?: () => void;
}

export const LogoutButton: FC<LogoutButtonProps> = ({ onClick }) => {
  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정
  return (
    <SoundButton type='button' onClick={onClick} playSound={shouldPlaySound}>
      <img
        alt='로그아웃'
        src={LogoutBtnIconPng}
        style={{ width: 116, height: 23 }}
      />
    </SoundButton>
  );
};
