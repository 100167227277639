import { PlaySessionType } from '../types/PlaySession.type';
import { getStockInfo } from './getStockInfo';

// 답변으로 그룹지어진 주가
export function getStockAnswerGroup(
  userId: string,
  session: PlaySessionType,
  useAmount = false
) {
  const turn_info = session.turn_info[session.turn_info.length - 1];
  const result: Record<
    string,
    {
      _id: string;
      name: String;
      price: number;
      diff: number;
      diffRate: number;
      amount: number;
    }[]
  > = {};
  const users = session.users.find((v) => v._id === userId);
  const stockMap: Record<string, number> = !users
    ? {}
    : users.stock_info[users.stock_info.length - 1].stocks.reduce(
        (acc, value) => {
          acc[value._id] = value.amount;
          return acc;
        },
        {} as Record<string, number>
      );
  (turn_info?.teams || []).forEach((v) => {
    if (v.final_choice === '') {
      return;
    }
    if (result[v.final_choice] === undefined) {
      result[v.final_choice] = [];
    }
    const stock = getStockInfo(v._id, session.stocks);
    result[v.final_choice].push({
      _id: v._id,
      name: stock.name,
      price: stock.price,
      diff: stock.price - stock.prev_price,
      diffRate:
        stock.prev_price === 0
          ? 0
          : (stock.price - stock.prev_price) / stock.prev_price,
      amount: stockMap[v._id] ?? 0,
    });
  });
  Object.keys(result).forEach((key) => {
    result[key] = result[key].sort((a, b) => {
      if (useAmount === true) {
        if (a.amount === 0 && b.amount === 0) {
          return a.price > b.price ? 1 : -1;
        }
        if (a.amount === 0) {
          return 1;
        }
        if (b.amount === 0) {
          return -1;
        }
      }
      return a.price > b.price ? 1 : -1;
    });
  });
  return result;
}
