import { HttpClient } from './http-client';

async function get() {
  const res = await HttpClient.get('theme');
  return res.data;
}

export const ThemeApis = {
  get,
};
