export enum GameStep {
  WAIT = 'wait',
  STOCK_DASHBOARD = 'stock_dashboard',
  FIRST_DECISION = 'first_decision',
  FIRST_DECISION_DONE = 'first_decision:done',
  PERSONAL_STATEMENT = 'personal_statement',
  FREE_DISCUSSION = 'free_discussion',
  FINAL_DECISION = 'final_decision',
  FINAL_DECISION_DONE = 'final_decision:done',
  STOCK_DEAL = 'stock_deal',
  TEAM_PRESENTATION = 'team_presentation',
  RESULT = 'result',
}
