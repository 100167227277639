import { FC } from 'react';
import { StockInfoBgPng } from '../../../../assets/images';
import classNames from 'classnames';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { getMyTeam } from '../../../../utils/getMyTeam';
import { getStockInfo } from '../../../../utils/getStockInfo';
import { getFormattedDiff } from '../../../../utils/getFormattedDiff';
import { useThemeContext } from '../../../../contexts/theme/useThemeContext';

interface MyTeamStockProps {}

export const MyTeamStock: FC<MyTeamStockProps> = () => {
  const theme = useThemeContext();
  const { session, teamCode } = usePlaySessionContext();
  const team = getMyTeam(teamCode, session.teams);
  const { prev_price, price } = getStockInfo(team?._id ?? '', session.stocks);
  const diff = price - prev_price;
  const rate = prev_price === 0 ? 100 : (diff * 100) / prev_price;
  const ourTeamColor = theme.our_team_color;
  return (
    <div
      className="w-full bg-cover bg-bottom bg-white bg-no-repeat flex flex-1"
      style={{
        backgroundImage: `url(${StockInfoBgPng})`,
        padding: '34px 30px',
      }}
    >
      <div>
        <p className="text-m6 text-darkBlueGray mb-3.5">
          <span
            style={{
              color: ourTeamColor,
            }}
          >
            {team?.name ?? 'UNKNOWN'}팀 (우리팀)
          </span>{' '}
          현재 주가
        </p>
        <p className="text-darkBlueGray text-m1">
          <strong className="text-m1">{price.toLocaleString('ko-KR')}</strong>{' '}
          <span className="text-m4">원</span>
        </p>
        <p
          className={classNames('text-right', {
            'text-coral': diff >= 0,
            'text-dodgerBlue': diff < 0,
          })}
          style={{ fontSize: 21.5 }}
        >
          {getFormattedDiff({
            price: diff,
            prefixSpace: false,
            rate: rate,
          })}
        </p>
      </div>
    </div>
  );
};
