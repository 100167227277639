import classNames from "classnames";
import { useEffect, FC, useState, useRef } from "react";
import { useTimer } from "react-timer-hook";
import useSound from "use-sound";
import { EndAlarm, LeftThirtySecondAlarm } from "../../assets/audio";
import { usePlaySessionContext } from "../../contexts/play-session/usePlaySessionContext";
import { HttpClient } from "../../apis/http-client";

interface IUseInterval {
  (callback: () => void, interval: number): void;
}

const useInterval: IUseInterval = (callback, interval) => {
  const savedCallback = useRef<(() => void) | null>(null);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    let id = setInterval(tick, interval);
    return () => clearInterval(id);
  }, [interval]);
};

export const Timer: FC<{
  expiryTimestamp: Date | string;
  onExpire: () => void;
}> = ({ expiryTimestamp, onExpire }) => {
  const getTime = (date: Date) => {
    return new Date(date.getTime() + adjustTime);
  };
  const [adjustTime, setAdjustTime] = useState(0);

  const { minutes, seconds, restart } = useTimer({
    expiryTimestamp: getTime(
      typeof expiryTimestamp === "string"
        ? new Date(expiryTimestamp)
        : expiryTimestamp
    ),
    autoStart: true,
    onExpire,
  });

  const { session } = usePlaySessionContext();
  const shouldPlaySound = session.is_effect_sound; // 효과음 재생 여부 결정

  const [playLeftThirtySecondAlarm] = useSound(LeftThirtySecondAlarm);
  const [playEndAlarm] = useSound(EndAlarm);

  useEffect(() => {
    if (minutes === 0 && seconds === 30 && shouldPlaySound) {
      playLeftThirtySecondAlarm();
    }
    if (minutes === 0 && seconds === 0 && shouldPlaySound) {
      playEndAlarm();
    }
  }, [
    minutes,
    playEndAlarm,
    playLeftThirtySecondAlarm,
    seconds,
    shouldPlaySound,
  ]);

  useEffect(() => {
    restart(
      getTime(
        typeof expiryTimestamp === "string"
          ? new Date(expiryTimestamp)
          : expiryTimestamp
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTimestamp, adjustTime]);

  useInterval(() => {
    (async () => {
      const { data: serverTime } = await HttpClient.post(`/game/get-time`);
      setAdjustTime(new Date().getTime() - serverTime);
    })();
  }, 10000);

  useEffect(() => {
    (async () => {
      const { data: serverTime } = await HttpClient.post(`/game/get-time`);
      setAdjustTime(new Date().getTime() - serverTime);
    })();
  }, []);

  return (
    <p
      className={classNames("font-digit font-bold text-p1", {
        "text-coral": minutes === 0 && seconds <= 30,
        "text-white": minutes !== 0 || seconds > 30,
      })}
    >
      {minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </p>
  );
};
