import { PlaySessionType } from '../types/PlaySession.type';

export function getStockHasAmount(
  userId: string,
  teamId: string,
  users: PlaySessionType['users']
) {
  const user = users.find((v) => v._id === userId);
  const stocks = user?.stock_info ?? [];
  const current_stock = stocks[stocks.length - 1];
  const amountinfo = current_stock?.stocks ?? [];
  const amount = amountinfo.find((v) => v._id === teamId);
  return amount?.amount ?? 0;
}
