import classNames from 'classnames';
import { FC } from 'react';
import { useThemeContext } from '../contexts/theme/useThemeContext';

interface StepItemProps {
  hasArrow?: boolean;
  children?: any;
  active?: boolean;
}

export const StepItem: FC<StepItemProps> = ({
  active = false,
  children,
  hasArrow = true,
}) => {
  const theme = useThemeContext();
  const stepColor = theme.pc_header_step_color;

  return (
    <div
      className={classNames('stepItem', { active })}
      style={{
        border: active ? stepColor : undefined,
        backgroundColor: active ? stepColor : undefined,
      }}
    >
      <span className="font-medium text-p6 translate-y-1">{children}</span>
      {hasArrow ? (
        <>
          <div className="innerArrow"></div>
          <div className="outterArrow"></div>
        </>
      ) : null}
    </div>
  );
};
