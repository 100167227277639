import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePlaySessionContext } from '../../../../contexts/play-session/usePlaySessionContext';
import { Header } from '../components/Header';
import { Round2WaitPage } from './wait';
import { Round2StockDashBoard } from './stock-dashboard';
import { Round2FinalDecision } from './final-decision';
import { TeamPresentation } from './team-presentation';
import { StockDeal } from './stock-deal';
import { GameStep } from '../../../../constants/step-enum';

interface Round2IndexProps {}

const Round2DetailRoutes: FC = () => {
  const { session } = usePlaySessionContext();

  return (
    <div className="w-full flex flex-col h-full">
      <Header
        startDates={
          session.current_step === GameStep.FINAL_DECISION_DONE
            ? new Date()
            : session.currentStepStartDate
            ? new Date(session.currentStepStartDate)
            : null
        }
        endDates={
          session.current_step === GameStep.FINAL_DECISION_DONE
            ? null
            : session.currentStepExpiryDate
            ? new Date(session.currentStepExpiryDate)
            : null
        }
      />
      <Routes>
        <Route path="/stock-dashboard" Component={Round2StockDashBoard}></Route>
        <Route path="/final-decision" Component={Round2FinalDecision}></Route>
        <Route path="/team-presentation" Component={TeamPresentation}></Route>
        <Route path="/stock-deal" Component={StockDeal}></Route>
      </Routes>
    </div>
  );
};

export const Round2Index: FC<Round2IndexProps> = () => {
  return (
    <Routes>
      <Route path="/wait" Component={Round2WaitPage}></Route>
      <Route path="/*" Component={Round2DetailRoutes}></Route>
    </Routes>
  );
};
