import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { PlaySessionType } from '../../types/PlaySession.type';
import { PlaySessionContext, PlaySessionDispatch } from './context';
import { gameSocket } from '../../socket/socket';
import { usePlaySessionContext } from './usePlaySessionContext';
import { usePlaySessionDispatch } from './usePlaySessionDispatch';
import { useNavigate } from 'react-router-dom';

interface PlaySessionProviderProps {
  initialSession: PlaySessionType;
  teamCode: string;
  userId?: string;
  children?: any;
}

const SocketListner: FC = () => {
  const { teamCode, userId, session } = usePlaySessionContext();
  const { setPlaySession } = usePlaySessionDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let timeout: any;
    function onBroadCast(session: PlaySessionType, date: string) {
      setPlaySession(session, date);
    }

    let interval: NodeJS.Timer | null = null;

    function onBanUser() {
      sessionStorage.removeItem('LastLoginClientId');
      sessionStorage.removeItem('LastLoginInfo');
      navigate('/');
    }

    function onConnect() {
      gameSocket.on('BROADCAST', onBroadCast);
      gameSocket.emit('SEND_MY_INFO', {
        session_id: session._id,
        teamCode,
        userId,
      });
      gameSocket.on('BAN_USER', onBanUser);

      if (userId !== undefined) {
        // console.log(userId);
        gameSocket.emit('KEEP_ALIVE');
      }
      interval = setInterval(() => {
        if (userId !== undefined) {
          gameSocket.emit('KEEP_ALIVE');
        }
      }, 20 * 1000);
    }
    function onDisconnect() {
      gameSocket.off('BROADCAST', onBroadCast);
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    }
    gameSocket.on('connect', onConnect);
    gameSocket.on('disconnect', onDisconnect);
    gameSocket.connect();

    return () => {
      gameSocket.off('connect', onConnect);
      gameSocket.off('disconnect', onDisconnect);
      gameSocket.off('BROADCAST', onBroadCast);
      gameSocket.disconnect();
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return null;
};

export const PlaySessionProvider: FC<PlaySessionProviderProps> = ({
  initialSession,
  teamCode,
  children,
  userId,
}) => {
  const lastDate = useRef<Date | null>(null);
  const [playSession, setPlaySession] = useState<{
    session: PlaySessionType;
    teamCode: string;
    userId?: string;
  }>({
    session: initialSession,
    teamCode: teamCode,
    userId,
  });

  const dispatch = useMemo(() => {
    return {
      setPlaySession: (next: PlaySessionType, createdAt: string) => {
        const sendCreatedAt = new Date(createdAt);
        // 조건 lastDate.current가 null 이고 sendcreatedAt이 isNaN이 아닐때 적용되어야한다.
        // if (lastDate.current && (isNaN(sendCreatedAt.getTime()) === true || )) {
        //   return;
        // }
        if (
          lastDate.current === null ||
          (isNaN(sendCreatedAt.getTime()) === false &&
            sendCreatedAt > lastDate.current)
        ) {
          lastDate.current = new Date(createdAt);
          setPlaySession((prev) => ({ ...prev, session: next }));
        }
      },
      dispatch: setPlaySession,
    };
  }, []);

  return (
    <PlaySessionContext.Provider
      value={{
        ...playSession,
        is_effect_sound: playSession.session.is_effect_sound, // 컨텍스트 값에 추가
        round_2_start: playSession.session.round_2_start, // session 객체에서 round_2_start 추출
      }}
    >
      <PlaySessionDispatch.Provider value={dispatch}>
        <SocketListner />
        {children}
      </PlaySessionDispatch.Provider>
    </PlaySessionContext.Provider>
  );
};
