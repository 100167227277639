import { FC } from "react";
import { ScenarioViewer } from "../components/ScenarioViewer";
import { usePlaySessionContext } from "../../../../contexts/play-session/usePlaySessionContext";
import { getMyTeam } from "../../../../utils/getMyTeam";
import { getScenarioInfo } from "../../../../utils/getScenarioInfo";
import { getPresenterName } from "../../../../utils/getPresenterName";
import { PresenterChoiceModal } from "../components/PresenterChoiceModal";
import { GameStep } from "../../../../constants/step-enum";

export const FirstDecision: FC = () => {
  const { session, teamCode } = usePlaySessionContext();
  const myTeam = getMyTeam(teamCode, session.teams);
  const presenter = getPresenterName(myTeam?._id ?? "", session);
  const scenario = getScenarioInfo({
    myTeam,
    balance: session.balance,
    playtime: session.playtime,
    round: session.current_round,
    scenarioInfo: session.gameScenarios,
    turn_info: session.turn_info,
  });

  if (scenario == null) {
    return (
      <div style={{ padding: "0 50px 51px" }}>
        <p className="text-p3 text-white font-medium text-center">
          사례 정보가 존재하지 않습니다.
        </p>
      </div>
    );
  }

  return (
    <div style={{ padding: "0 50px 51px" }}>
      <ScenarioViewer
        {...scenario}
        select={
          session.current_step === GameStep.FIRST_DECISION_DONE
            ? scenario.first_select
            : ""
        }
      />
      {!!presenter ? (
        <PresenterChoiceModal
          isOpen={session.current_step === GameStep.FIRST_DECISION_DONE}
          name={presenter}
          select={scenario.select}
        />
      ) : null}
    </div>
  );
};
